<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          v-on="on"
        >
          <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar size="40px">
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userInfo.name }}
          </span>
          <small class="text--disabled text-capitalize">Admin</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Wallet -->
      <v-list-item
        v-if="userInfo.role === 'admin'"
        link
      >
        <v-list-item-icon class="me-2">
          <v-icon
            size="22"
            class="mt-1"
          >
            {{ icons.mdiWallet }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content @click="wallet()">
          <v-list-item-title>Wallet</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Settings -->
      <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon
            size="22"
            class="mt-1"
          >
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content @click="settings()">
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Logout -->
      <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon
            size="22"
            class="mt-1"
          >
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content @click="logout()">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiWallet,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiWallet,
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    ...mapActions([
      'clearUser',
    ]),
    logout() {
      this.$nprogress.start()
      axios
        .get('auth/admin/logout')
        .then(() => {
          this.$nprogress.done()
          this.clearUser()
          this.$router.go('/login')
        })
        .catch(error => {
          this.$nprogress.done()
          this.clearUser()
          this.$router.go('/login')
          this.$notification.error(error.response.data.message)
        })
    },
    settings() {
      this.$router.push('/settings')
    },
    wallet() {
      this.$router.push('/wallet')
    },
  },
}
</script>
